/* -----------------------------------------------------------------------------
 *
 * Core: Print
 *
 * ----------------------------------------------------------------------------- */

@media print {
  *,
  ::before,
  ::after {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[ href ]::after {
    content: ' (' attr( href ) ')';
  }

  abbr[ title ]::after {
    content: ' (' attr( title ) ')';
  }

  a[ href^='javascript:' ]::after,
  a[ href^='#' ]::after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid gray;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  /* Print Styles */

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: white !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid gray !important;
  }

  .navigation,
  .menu {
    display: none;
  }
}
