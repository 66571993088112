/* -----------------------------------------------------------------------------
 *
 * Variables: Spacers
 *
 * ----------------------------------------------------------------------------- */

:root {
  /* -----------------------------------------------------------------------------
	 * Spacers (margin & padding)
	 * ----------------------------------------------------------------------------- */

  --spacer: 30px;
  --spacer-xs: 15px;
  --spacer-s: 20px;
  --spacer-m: 35px;
  --spacer-lg: 60px;
  --spacer-xxlg: 160px;


  /* -----------------------------------------------------------------------------
	 * Spacers (WP Admin Bar)
	 * ----------------------------------------------------------------------------- */

  --spacer-wpadmin-mobile: 46px;
  --spacer-wpadmin-desktop: 32px;
}
