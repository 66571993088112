@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?qtoq7b');
  src:  url('fonts/icomoon.eot?qtoq7b#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?qtoq7b') format('truetype'),
    url('fonts/icomoon.woff?qtoq7b') format('woff'),
    url('fonts/icomoon.svg?qtoq7b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-right:before {
  content: "\eaeb";
}
.icon-chevron-left:before {
  content: "\eaec";
}
.icon-chevron-down:before {
  content: "\eaed";
}
.icon-chevron-up:before {
  content: "\eaee";
}
.icon-dice:before {
  content: "\e915";
}
.icon-spades:before {
  content: "\e917";
}
.icon-clubs:before {
  content: "\e918";
}
.icon-diamonds:before {
  content: "\e919";
}
.icon-coin-dollar:before {
  content: "\e93b";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-hearts:before {
  content: "\e9da";
}
.icon-calculator:before {
  content: "\e900";
}
.icon-dollar:before {
  content: "\e901";
}
.icon-dollar-1:before {
  content: "\e902";
}
.icon-banknote:before {
  content: "\e903";
}
.icon-wallet:before {
  content: "\e904";
}
.icon-envelope-outline:before {
  content: "\f003";
}
.icon-user:before {
  content: "\f007";
}
.icon-close:before {
  content: "\f00d";
}
.icon-phone:before {
  content: "\f095";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-money:before {
  content: "\f0d6";
}
.icon-envelope:before {
  content: "\f0e0";
}
