/* -----------------------------------------------------------------------------
 *
 * Variables: Colors
 *
 * ----------------------------------------------------------------------------- */

:root {
  /* Black & White */
  --color-white: rgb( 255, 255, 255 );
  --color-black: rgb( 0, 0, 0 );

  /* Body */
  --color-mine-shaft: rgb( 31, 31, 31 ); /* #1F1F1F */
  --color-mine-shaft-1: rgb( 51, 51, 51 ); /* #333333 */
  --color-mercury: rgb( 227, 227, 227 ); /* #E3E3E3 */
  --color-dove-gray: rgb( 102, 102, 102 ); /* #666666 */

  /* Theme Colors */
  --color-primary: rgb( 87, 39, 128 );               /* Eminence */
  --color-secondary: rgb( 20, 25, 40 );              /* Midnight Express */
  --color-tertiary: rgb( 150, 255, 245 );            /* Electric Blue */

  /* Theme Colors */
  --color-cyan-1: rgb( 145, 255, 255 ); /* #91ffff */
  --color-cyan-2: rgb( 8, 164, 167 ); /* #08a4a7 */
  --color-cyan-3: rgb( 233, 255, 255 ); /* #e9ffff */
  --color-cyan-4: rgb( 77, 254, 209 ); /* #4dfed1 */
  --color-cyan-5: rgb( 1, 219, 182 ); /* 	#0bdbb6 */


  /* Special Color */
  --color-amaranth: rgb( 233, 30, 99 );        /* #E91E63 */
  --color-maroon-flush: rgb( 194, 24, 88 );    /* #C21858 */
  --color-royal-health: rgb( 156, 39, 128 );   /* #9C2780 */
  --color-eminence: rgb( 87, 39, 128 );        /* #572780 */
  --color-bay-of-many: rgb( 39, 42, 128 );     /* #272A80 */
  --color-calypso: rgb( 39, 104, 128 );        /* #276880 */
  --color-havelock-blue: rgb( 87, 172, 220 );  /* #57ACDC */
  --color-viking: rgb( 87, 220, 190 );         /* #57DCBE */
  --color-emerald: rgb( 96, 198, 137 );        /* #60C689 */
}
