/* -----------------------------------------------------------------------------
  *
  * Core
	*
	* These are styles of the main website elements - body, input, button, ul, ol, etc.
	* In this section, we use mainly HTML tags and attribute selectors, in exceptional cases - classes
	* (for example, if you have JavaScript-style selections);
  *
  * ----------------------------------------------------------------------------- */

html {
  scroll-padding-top: 53px;
}

body {
  min-height: 100vh;
  background-color: var( --color-mine-shaft );
  color: var( --color-mercury );
  font-family: var( --font-primary );
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.site {
  min-height: 100vh;
}

#__next {
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100vh;
}



/* .site {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: 1rem;
}

canvas {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 6px;
} */
