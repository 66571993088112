:root {
	--icon-chevron-right: '\eaeb';
	--icon-chevron-left: '\eaec';
	--icon-chevron-down: '\eaed';
	--icon-chevron-up: '\eaee';
	--icon-dice: '\e915';
	--icon-spades: '\e917';
	--icon-clubs: '\e918';
	--icon-diamonds: '\e919';
	--icon-coin-dollar: '\e93b';
	--icon-info: '\ea0c';
	--icon-hearts: '\e9da';
	--icon-calculator: '\e900';
	--icon-dollar: '\e901';
	--icon-dollar-1: '\e902';
	--icon-banknote: '\e903';
	--icon-wallet: '\e904';
	--icon-envelope-outline: '\f003';
	--icon-user: '\f007';
	--icon-close: '\f00d';
	--icon-phone: '\f095';
	--icon-users: '\f0c0';
	--icon-money: '\f0d6';
	--icon-envelope: '\f0e0';
}
