/* -----------------------------------------------------------------------------
 *
 * Core: Reset
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Make viewport responsive.
 * ----------------------------------------------------------------------------- */
@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}


/* -----------------------------------------------------------------------------
 * Box model.
 * ----------------------------------------------------------------------------- */

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

*,
::before,
::after {
  box-sizing: inherit;
}

/* -----------------------------------------------------------------------------
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers.
 * ----------------------------------------------------------------------------- */

a {
  &:active,
  &:hover {
    outline-width: 0;
  }
}

/* -----------------------------------------------------------------------------
 * Give a help cursor to elements that give extra info on `:hover`.
 * ----------------------------------------------------------------------------- */

abbr,
dfn {
  &[ title ] {
    cursor: help;
  }
}


/* -----------------------------------------------------------------------------
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * ----------------------------------------------------------------------------- */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/* -----------------------------------------------------------------------------
 * Make images, iframes and videos responsive.
 * ----------------------------------------------------------------------------- */

img,
video {
  max-width: 100%;
  height: auto;
}

iframe {
  max-width: 100%;
}

/* -----------------------------------------------------------------------------
 * Remove default fieldset styles.
 * ----------------------------------------------------------------------------- */

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

/* -----------------------------------------------------------------------------
 * Remove default figure styles.
 * ----------------------------------------------------------------------------- */

figure {
  margin: 0 0 20px;
  margin-right: 0;
  margin-left: 0;

  &.entry-thumbnail {
    margin-right: 0;
    margin-left: 0;
  }
}

/* -----------------------------------------------------------------------------
 * Make fields inherit font properties.
 * ----------------------------------------------------------------------------- */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  font-weight: normal;
  line-height: normal;
}

/* -----------------------------------------------------------------------------
 * So that label text is aligned to the top.
 * ----------------------------------------------------------------------------- */

textarea {
  vertical-align: top;
  resize: vertical;
}

/* -----------------------------------------------------------------------------
 * Remove outline from tabindex.
 * ----------------------------------------------------------------------------- */

[ tabindex='-1' ]:focus {
  outline: none !important;
}

/* -----------------------------------------------------------------------------
 * Maps canvas.
 * ----------------------------------------------------------------------------- */
.map_canvas,
#map_canvas {
  img,
  embed,
  object {
    max-width: none !important;
  }
}
