/* -----------------------------------------------------------------------------
 *
 * Core: Tables
 *
 * ----------------------------------------------------------------------------- */


table {
  border-spacing: 0;
  text-align: left;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  caption-side: top;
  font-style: italic;
  text-align: center;
}

thead {
  vertical-align: bottom;
}

th {
  font-weight: bolder;
}

th,
td {
  padding: 0;
}

td {
  vertical-align: top;
}
