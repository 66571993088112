/* -----------------------------------------------------------------------------
 *
 * Core: Text
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Modifier: Text Large
 * ----------------------------------------------------------------------------- */

.text-large {
  font-size: 120%;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Small
 * ----------------------------------------------------------------------------- */

.text-small {
  font-size: 80%;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Left
 * ----------------------------------------------------------------------------- */

.text-left {
  text-align: left;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Right
 * ----------------------------------------------------------------------------- */

.text-right {
  text-align: right;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Center
 * ----------------------------------------------------------------------------- */

.text-center {
  text-align: center;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Left
 * ----------------------------------------------------------------------------- */

.text-justify {
  text-align: justify;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text No-Wrap
 * ----------------------------------------------------------------------------- */

.text-nowrap {
  white-space: nowrap;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Lowercase
 * ----------------------------------------------------------------------------- */

.text-lowercase {
  text-transform: lowercase;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Uppercase
 * ----------------------------------------------------------------------------- */

.text-uppercase {
  text-transform: uppercase;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Capitalize
 * ----------------------------------------------------------------------------- */

.text-capitalize {
  text-transform: capitalize;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Underline
 * ----------------------------------------------------------------------------- */

.text-underline {
  text-decoration: underline;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Strike-Through
 * ----------------------------------------------------------------------------- */

.text-strikethrough {
  text-decoration: line-through;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Overflow
 * ----------------------------------------------------------------------------- */

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text hyphenate
 * ----------------------------------------------------------------------------- */

.text-hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/* -----------------------------------------------------------------------------
 * Modifier: Text Line
 * ----------------------------------------------------------------------------- */

.text-line,
.text-line-left,
.text-line-right {
  display: block;
  overflow: hidden;
  line-height: 1.5;
}

.text-line {
  text-align: center;
}

.text-line-left {
  text-align: right;
}

.text-line-right {
  text-align: left;
}

.text-line::before,
.text-line::after,
.text-line-left::before,
.text-line-right::after {
  content: '';
  display: inline-block;
  position: relative;
  width: 50%;
  height: 1px;
  background-color: currentcolor;
  vertical-align: middle;
}

.text-line::before {
  right: 16px;
  margin-left: -50%;
}

.text-line::after {
  left: 16px;
  margin-right: -50%;
}

.text-line-left::before {
  right: 16px;
  width: 100%;
  margin-left: -100%;
}

.text-line-right::after {
  left: 16px;
  width: 100%;
  margin-right: -100%;
}
